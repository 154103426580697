<template>
  <div class="cms-media-file-editor">
    <div class="cms-media-file-wrapper">
      <cms-media-file v-bind="sanitizedValue"></cms-media-file>
      <ui-icon
        value="mdi:pencil"
        class="ui-clickable"
        @click="isEditing = true"
      ></ui-icon>
    </div>

    <!-- <ui-item
      v-bind="sanitizedValue"
      @click="isEditing = true"
      class="ui-clickable"
    ></ui-item>-->

    <div
      v-show="isEditing"
      class="file-editor-form"
    >
      <input
        type="text"
        class="ui-native"
        placeholder="Titulo"
        v-model="innerValue.text"
      />
      <input
        type="text"
        class="ui-native"
        placeholder="Descripción"
        v-model="innerValue.secondary"
      />

      <div class="file-editor-form-footer">
        <button
          type="button"
          class="ui-button --main"
          @click="accept"
        >OK</button>
        <button
          type="button"
          class="ui-button --cancel"
          @click="cancel"
        >Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
import CmsMediaFile from './File.vue';
import { UiIcon, UiItem } from '@/modules/ui/components';

export default {
  name: 'cms-media-file-editor',
  components: { CmsMediaFile, UiIcon, UiItem },
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      innerValue: null,
      isEditing: false
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerValue = JSON.parse(JSON.stringify(newValue));
        this.isEditing = this.isEmpty;
      }
    }
  },

  computed: {
    isEmpty() {
      return (
        !this.innerValue.href &&
        !this.innerValue.text &&
        !this.innerValue.secondary
      );
    },

    sanitizedValue() {
      return {
        href: this.innerValue.href,
        text: this.innerValue.text || 'Sin título',
        secondary: this.innerValue.secondary,
        icon: this.innerValue.icon || 'mdi:file'
      };
    }
  },

  methods: {
    accept() {
      this.$emit('input', JSON.parse(JSON.stringify(this.innerValue)));
      this.isEditing = false;
    },

    cancel() {
      this.innerValue = JSON.parse(JSON.stringify(this.value));
      this.isEditing = false;

      if (this.isEmpty) {
        this.$emit('delete');
      }
    }
  }
};
</script>

<style lang="scss">
.cms-media-file-editor {
  
  .cms-media-file-wrapper {
    display: flex;

    .cms-media-file {
      flex: 1;
    }

    .ui-icon {
      width: 48px;
    }
  }

  .file-editor-form {
    padding-left: 50px;
    padding-right: 12px;

    input {
      display: block;
      width: 100%;
    }

    .file-editor-form-footer {
      text-align: right;
      margin-top: var(--ui-breathe);
    }
  }
}
</style>